<template>
    <div class="">

        <bsa-questionnaire
            v-if="ready && !closed && !form && !message"
            :modal="settings.modal"
            :questionnaire="this.dataService.questionnaire.collection"
            :settings="this.dataService.questionnaire.settings"
            @load-form="loadForm($event)"
            @load-message="loadMessage($event)"
            @close="close()"
        />

        <transition name="fade">
            <bsa-form-stepper v-if="!closed && form" :form="form" @success="success()"/>
        </transition>

        <bsa-message
            :modal="settings.modal"
            v-if="!closed && message" :message="message"
            @close="close()"
        />

    </div>
</template>


<script>
    import { dataService } from '../services/DataService.js';
    import Questionnaire from './questionnaire/Questionnaire.vue';
    import FormStepper from './complaint_form/FormStepper.vue';
    import Message from './message/Message.vue';

    export default {
        components: {
            'bsa-questionnaire': Questionnaire,
            'bsa-form-stepper': FormStepper,
            'bsa-message': Message,
        },
        data() {
            return {
                dataService,
                form: null,
                message: null,
                settings: {},
                closed: true,
                ready: false,
            }
        },
        methods: {
            makeComplaint() {
                this.closed = false;
            },
            loadForm(obj) {
                const form = this.dataService.forms[obj.action_key];

                // for GTM tracking
                localStorage.complaintStep = dataService.formSteps[form.steps[0]].title;

                //we redirect to the right page unless we are already on it.
                if (this.settings.pageID === obj.action_key) {
                    this.form = form;
                    this.scrollTo('.form-step-container.active');
                } else {
                    localStorage.savedVueComplaintProcessData = JSON.stringify({
                        date: new Date(),
                        form: form,
                        dataService: this.dataService,
                    });
                    if (obj.redirectTo) {
                        location.href = obj.redirectTo;
                    }
                }
            },
            loadMessage(obj) {
                const msg = this.dataService.messages[obj.action_key];
                this.message = msg;
            },
            loadLocalStorage() {
                if (localStorage.savedVueComplaintProcessData) {
                    const data = JSON.parse(localStorage.savedVueComplaintProcessData);
                    const oneDay = 24 * 3600 * 1000;
                    const savedDate = new Date(data.date);

                    if (new Date() - savedDate < oneDay) {
                        this.form = data.form;
                        this.message = data.message;
                        this.dataService.setData(data.dataService);
                        this.ready = true;
                        this.makeComplaint();
                        this.clearLocalStorage();
                        return;
                    } else {
                        delete(localStorage.savedVueComplaintProcessData);
                    }
                }
                this.loadData();
            },
            clearLocalStorage() {
                delete(localStorage.savedVueComplaintProcessData);
            },
            success(){
                this.clearLocalStorage();
                delete(localStorage.complaintStep);
            },
            close() {
                this.closed = true;
                this.form = null;
                this.message = null;
            },
            reset() {
                this.form = null;
                this.message = null;
            },
            loadData() {
                dataService.getData()
                    .then(({data}) => {
                        // eslint-disable-next-line no-console
                        // console.log(data)
                        this.dataService.setData(data);
                        this.ready = true;
                        if (this.settings.autoStart) {
                            this.makeComplaint();
                        }
                    })
                    .catch(err => {
                        // We havent defined and error handling protocol for these cases.
                        // Simply printing them on the console for now
                        alert('We are sorry, there was an error loading our complaint form.  Please contact us or try again later.');
                        alert(err);
                    });
            },
            scrollTo(selector, options){
                if(typeof options === 'undefined') {
                    options = {};
                }
                if(typeof options.timeBeforeStart === 'undefined') {
                    options.timeBeforeStart = 200;
                }
                if(typeof options.animationSpeed === 'undefined') {
                    options.animationSpeed = 300;
                }
                const mergedOptions =
                {
                    ...{
                        easing: 'ease-out',
                        offset: -60,
                        force: true,
                        cancelable: false,
                        x: false,
                        y: true

                    },
                    ...options
                }

                const VueScrollTo = require('vue-scrollto');
                window.setTimeout(
                    function() {
                        VueScrollTo.scrollTo(selector, options.animationSpeed, mergedOptions);
                    },
                    options.timeBeforeStart
                );

            }
        },
        mounted() {
            this.settings = window.complaintAppSettings;
            window.addEventListener('makeAComplaintEvent', () => this.makeComplaint())

            if (this.settings.loadDataFromStorage) {
                this.loadLocalStorage();
            } else {
                this.loadData();
            }
        },
    }
</script>
