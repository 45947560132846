<template>
    <div class="questionnaire-container">
        <div :class="{overlay: modal}">
            <transition name="fadein">
                <div class="inner-container container" v-if="!loading">
                    <div class="top-strip"></div>
                    <div class="controls-container">
                        <button class="btn btn-back float-top-left" v-if="showBackButton" @click="back()">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 29 33">
                                <use xlink:href="/_resources/themes/base_dist/images/icons.svg#left-arrow"></use>
                            </svg>
                            Back
                        </button>
                        <button class="btn btn-close float-top-right" @click="close()">
                            Close
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 29 33">
                                <use xlink:href="/_resources/themes/base_dist/images/icons.svg#close-burger"></use>
                            </svg>
                        </button>
                    </div>
                    <div class="center-text">
                        <h6>Make a Complaint</h6>
                    </div>
                    <bsa-question
                        :question="questionnaire[currentQuestion]"
                        @load-question="loadQuestion($event)"
                        @load-form="loadForm($event)"
                        @load-message="loadMessage($event)"
                    />
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import Question from './Question.vue';

    export default {
        components: {
            'bsa-question': Question,
        },
        props: {
            questionnaire: {
                default: null
            },
            settings: {
                default: null
            },
            modal: {
                default: false
            },
        },
        data() {
            return {
                currentQuestion: this.settings.startAt,
                questionnairePath: [],
                loading: true,
            };
        },
        computed: {
            showBackButton() {
                return this.questionnairePath.length > 0;
            }
        },
        methods: {
            nextStep(answer) {
                this.questionnairePath.push(this.currentQuestion);
                this.currentQuestion = answer.action_key;
            },
            loadQuestion(answer) {
                this.nextStep(answer);
            },
            loadForm(answer) {
                this.$emit('load-form', answer);
            },
            loadMessage(answer) {
                this.$emit('load-message', answer);
            },
            back() {
                const pathSize = this.questionnairePath.length;
                this.currentQuestion = this.questionnairePath[pathSize - 1];
                this.questionnairePath.splice(pathSize - 1, 1);
            },
            close() {
                this.$emit('close');
            },
        },
        mounted() {
            setTimeout(() => this.loading = false, 50);
        },
    }
</script>
