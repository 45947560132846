import { HTTP } from './http_common';

function toFormData(data) {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
        if (Array.isArray(data[key])) {
            data[key].forEach(value => formData.append(`${key}[]`, value));
        } else {
            formData.append(key, data[key]);
        }
    });
    formData.append('Referrer', 'vue');

    return formData;
}


/**
 * Define the basic methods for a BSADataConnection Service.
 *
 * @export
 * @class BSADataConnection
 */
export class BasicConnection {

    constructor() {
        this.url = '';
        this.params = {};
    }

    /**
     * GET Query of object from the backend
     *
     * @param {*} [action=null]
     * @returns
     * @memberof BasicConnection
     */
    get(action=null) {
        const url = action ? `${this.url}/${action}` : `${this.url}`;
        return HTTP.get(url);
    }

    /**
     * Post object data for the creation a new instance.
     *
     * @param {*} data
     * @param {*} [action=null]
     * @param {boolean} [convertToFormData=false]
     * @returns
     * @memberof BasicConnection
     */
    post(data, action=null, convertToFormData=true) {
        const url = action ? `${this.url}${action}` : `${this.url}`;

        if (convertToFormData) {
            return HTTP.post(url, toFormData(data));
        } else {
            return HTTP.post(url, data);
        }
    }

}
