<template>
    <div>
        <editable-literal-field v-if="properties.info" :value="properties.info" :required="properties.required"/>
        <div :id="`EditableFileField_${uuid}`" class="field file form-group--no-label">
            <div class="middleColumn">
                <label class="left" :for="`EditableFileField_${uuid}`">
                    <input type="hidden" name="MAX_FILE_SIZE" :value="maxFileSizeValue">
                    <input
                        type="file"
                        name="SilverStripe\Forms\FileField"
                        class="file"
                        :id="`EditableFileField_${uuid}`"
                        @change="fileChange($event.target.name, $event.target.files)"
                    >
                    <span>
                        <span v-if="file" class="remove-file" @click="removeFile">✗</span>
                        <span v-if="file" class="label-info">{{ file.name }}</span>
                        <span v-else class="label-info">Drag or drop your file anywhere or</span>
                        <span class="btn">Choose a file</span>
                    </span>
                </label>
                <span v-if="showErrorMessage && !valid" class="message" :class="{bad: !valid}">Field is required</span>
            </div>
        </div>
    </div>
</template>

<script>
    import uuid from 'uuid4';

    import EditableLiteralField from './EditableLiteralField.vue';
    import { maxFileSize } from '../../settings/file_size.js';
    import { fileTypes } from '../../settings/file_types.js';

    export default {
        components: {
            EditableLiteralField,
        },
        props: {
            properties: {
                required: true
            },
        },
        data() {
            return {
                file: null,
                valid: false,
                uuid: uuid(),
                fileTypes,
                maxFileSize,
                showErrorMessage: false,
                showErrorMessageTimeout: null,
            };
        },
        watch: {
            file() {
                this.validate();
                this.$emit('input', this.file);
            },
        },
        computed: {
            accept() {
                return Object.values(this.fileTypes).join(',');
            },
            maxFileSizeValue() {
                return this.maxFileSize;
            }
        },
        methods: {
            fileChange(name, files) {
                this.file = files[0];
            },
            removeFile(){
                this.file = null;
                this.valid = false;
            },
            validate() {
                if(this.maxFileSize > 0 && this.getFileSize() > this.maxFileSize) {
                    let maxSizeInMB = this.maxFileSize / 1048576;
                    maxSizeInMB = Math.round(maxSizeInMB * 10) / 10;
                    let maxSizeInMBString = maxSizeInMB + " MegaBytes";
                    let msg = 'Sorry, your file is too big to be uploaded. The maximum is '+maxSizeInMBString+'. Please choose another file or reduce the current one in size.';
                    alert(msg);
                    this.valid = false;
                    this.file = null;
                } else {
                    if(this.isValidFileType() === false) {
                        const types =  Object.keys(this.fileTypes);
                        const last = types.pop();
                        const myTypes = types.join(', ') + ' and ' + last;
                        alert("Sorry, we can only accept the following file types " + myTypes + '.');
                        this.valid = false;
                        this.file = null;
                    } else {
                        if (this.properties.required) {
                            this.valid = !!this.file;
                        } else {
                            this.valid = true;
                        }
                    }
                }
                this.$emit('validated');
            },
            showErrors() {
                clearTimeout(this.showErrorMessageTimeout);
                this.showErrorMessage = true;
            },
            closeErrors() {
                this.showErrorMessageTimeout = setTimeout(() => this.showErrorMessage = false, 1000);
            },
            getFileSize() {
                if(this.file && !isNaN(this.file.size)) {
                    return this.file.size;
                } else {
                    // console.log('Could not work out size!!!');
                    // console.log(this.file);
                }
            },
            isValidFileType() {
                let valid = true;
                if (this.file && typeof this.file.name === 'string') {
                    // console.log(this.file);
                    const name = this.file.name;
                    valid = false;
                    const keys = Object.keys(this.fileTypes);
                    for (const key of keys) {
                        const ext = '.' + key;
                        const extLength = ext.length;
                        const fileLength = name.length;
                        const fileExtension = name.substr(fileLength - extLength, extLength);
                        // console.log(name);
                        // console.log(ext);
                        // console.log(extLength);
                        // console.log(fileExtension);
                        if (fileExtension.toLowerCase() === ext.toLowerCase()) {
                            valid = true;
                            break;
                        }
                    }
                } else {
                    // console.log('no file attached');
                }

                return valid;
            },
            getData() {
                return this.file;
            },



        },
        mounted() {
            this.validate();
        },
    }
</script>
