<template>
    <div :id="`TextField_Holder-${uuid}`" class="datepicker-container field text" :class="{focused: focus || !!buffer}">
        <label class="left" :for="`TextField-${uuid}`">{{ properties.label }} <span v-if="properties.required">*</span></label>
        <div class="middleColumn">
            <datepicker
                :name="`TextField-${uuid}`"
                :id="`TextField-${uuid}`"
                v-model="buffer"
                @opened="opened()"
                @closed="closed()"
                :clear-button="true"
                :disabledDates="disabledDates"
            >
                <span slot="afterDateInput" class="calendar-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 29 33">
                        <use xlink:href="/_resources/themes/base_dist/images/icons.svg#calendar-icon"/>
                    </svg>
                </span>
            </datepicker>
            <span v-if="(showErrorMessage && !valid )|| (!pristine && !valid)" class="message" :class="{bad: !valid}">Field is required</span>
        </div>
    </div>
</template>

<script>
    import uuid from 'uuid4';

    import Datepicker from 'vuejs-datepicker';

    export default {
        components: {
            Datepicker
        },
        props: {
            properties: {
                required: true
            },
            value: {
                required: true
            },
            disableFuture: {
                default: false,
            }
        },
        data() {
            return {
                buffer: this.value,
                valid: false,
                uuid: uuid(),
                focus: false,
                pristine: true,
                showErrorMessage: false,
                showErrorMessageTimeout: null,
                disabledDates: this.disableFuture ? {from: new Date()} : {}
            };
        },
        watch: {
            value() {
                this.buffer = this.value;
            },
            buffer() {
                this.validate();
                this.$emit('input', this.buffer);
            },
        },
        methods: {
            validate() {
                if (this.properties.required) {
                    this.valid = !!this.buffer;
                } else {
                    this.valid = true;
                }
                this.$emit('validated');
            },
            opened() {
                this.focus = true;
            },
            closed() {
                this.pristine = false;
                if (!this.buffer) {
                    this.focus = false;
                }
            },
            showErrors() {
                clearTimeout(this.showErrorMessageTimeout);
                this.showErrorMessage = true;
            },
            closeErrors() {
                this.showErrorMessageTimeout = setTimeout(() => this.showErrorMessage = false, 1000);
            },
            getData() {
                if (this.buffer) {
                    const d = this.buffer;
                    return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
                } else {
                    return '';
                }
            }
        },
        mounted() {
            this.validate();
        },
    };
</script>
