<template>
    <div :id="`EditableLiteralField_${uuid}`" class="html-block field CompositeField composite form-group--no-label">
        <div class="middleColumn" v-html="buffer">
        </div>
    </div>
</template>

<script>
    import uuid from 'uuid4';

    export default {
        props: {
            value: {
                required: true,
            },
            required: {
                default: false,
            }
        },
        data() {
            return {
                uuid: uuid(),
            };
        },
        computed: {
            buffer() {
                return this.value;
            }
        },
    };
</script>
