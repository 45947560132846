<template>
    <div :id="`TextAreaField_Holder-${uuid}`"
         class="field textarea"
         :class="{noMargin}"
         >

        <label class="left" :for="`TextAreaField-${uuid}`">{{ properties.label }} <span v-if="properties.required">*</span></label>
        <div class="middleColumn" :class="{noMargin}">
            <textarea
                :name="`TextAreaField-${uuid}`"
                class="text"
                :placeholder="properties.placeholder"
                :id="`TextAreaField-${uuid}`"
                v-model="buffer"
                @focus="focus=true"
                @blur="blur()"
            />
            <span v-if="(showErrorMessage && !valid )|| (!pristine && !valid)" class="message" :class="{bad: !valid}">Field is required</span>
        </div>
    </div>
</template>

<script>
    import uuid from 'uuid4';

    export default {
        props: {
            properties: {
                required: true
            },
            value: {
                type: String,
            },
            noMargin: {
                default: false
            },
        },
        data() {
            return {
                buffer: this.value,
                valid: false,
                uuid: uuid(),
                focus: false,
                pristine: true,
                showErrorMessage: false,
                showErrorMessageTimeout: null,
            };
        },
        watch: {
            value() {
                this.buffer = this.value;
            },
            buffer() {
                this.validate();
                this.$emit('input', this.buffer);
            },
        },
        methods: {
            blur() {
                this.pristine = false;
                this.focus = false;
            },
            validate() {
                if (this.properties.required) {
                    this.valid = !!this.buffer;
                } else {
                    this.valid = true;
                }
                this.$emit('validated');
            },
            showErrors() {
                clearTimeout(this.showErrorMessageTimeout);
                this.showErrorMessage = true;
            },
            closeErrors() {
                this.showErrorMessageTimeout = setTimeout(() => this.showErrorMessage = false, 1000);
            },
            getData() {
                return this.buffer;
            }
        },
        mounted() {
            this.validate();
        },
    };
</script>
