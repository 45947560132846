<template>
    <div :id="`DropdownField_Holder-${uuid}`" class="field field dropdown">
        <label class="left" :for="`DropdownField-${uuid}`">{{ properties.label }} <span v-if="properties.required">*</span></label>
        <div class="middleColumn typography">
            <select
                :name="`DropdownField-${uuid}`"
                :id="`DropdownField-${uuid}`"
                v-model="buffer"
            >
                <option v-for="(option, i) in properties.options" :key="i" :value="option.value">
                    {{option.label}}
                </option>
            </select>
            <span v-if="showErrorMessage && !valid" class="message" :class="{bad: !valid}">Field is required</span>
        </div>
    </div>
</template>

<script>
    import uuid from 'uuid4';

    export default {
        props: {
            properties: {
                required: true
            },
            value: {
                required: true
            },
        },
        data() {
            return {
                buffer: this.value,
                valid: false,
                uuid: uuid(),
                showErrorMessage: false,
                showErrorMessageTimeout: null,
            };
        },
        watch: {
            value() {
                this.buffer = this.value;
            },
            buffer() {
                this.validate();
                this.$emit('input', this.buffer);
            },
        },
        methods: {
            validate() {
                if (this.properties.required) {
                    // console.log('dropdown required');
                    // console.log(this.buffer);
                    this.valid = !!this.buffer;
                } else {
                    // console.log('dropdown NOT required');
                    this.valid = true;
                }
                this.$emit('validated');
            },
            showErrors() {
                clearTimeout(this.showErrorMessageTimeout);
                this.showErrorMessage = true;
            },
            closeErrors() {
                this.showErrorMessageTimeout = setTimeout(() => this.showErrorMessage = false, 1000);
            },
            getData() {
                return this.buffer;
            }
        },
        mounted() {
            this.validate();
        },
    };
</script>
