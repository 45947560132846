<template>
    <div class="question-container">

        <div v-if="!question" class="center-text">
            <h4 class="error-question">[Question] Something went wrong, please contact technical support...</h4>
        </div>

        <div v-else>

            <div class="center-text">
                <div class="icon-frame">
                    <img src="/_resources/themes/base_dist/images/temp/icon-complaints-white.png" alt="complaint-icon"/>
                </div>
            </div>
            <div class="question-content">
                <h3 class="question-injectable center-text" v-html="question.value"></h3>
            </div>
            <div class="buttons-container center-text">
                <button
                    v-for="(answer, i) in question.answers" :key="i"
                    class="btn btn-answers"
                    :class="{last: i === (question.answers.length - 1)}"
                    @click="answerClicked(answer)"
                    >
                    {{ answer.text }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            question: {
                type: Object,
                required: true,
            }
        },
        data() {
            return {

            }
        },
        methods: {
            answerClicked(answer) {
                this.$emit(answer.action, answer)
            }
        },
    }
</script>
