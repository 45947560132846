<template>
    <div class="questionnaire-container">
        <div :class="{overlay: modal}">
            <div class="inner-container container" v-if="!loading">
                <div class="top-strip"></div>
                <div class="controls-container">
                    <button class="btn btn-close float-top-right" @click="close()">
                        Close
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 29 33">
                            <use xlink:href="/_resources/themes/base_dist/images/icons.svg#close-burger"></use>
                        </svg>
                    </button>
                </div>

                <div v-if="!message" class="center-text">
                    <h4 class="error-question">Something went wrong, please contact technical support...</h4>
                </div>

                <div v-else class="question-content">
                    <div class="question-injectable center-text" v-html="message.value"></div>
                    <div class="buttons-container center-text">
                        <button
                            class="btn btn-answers last"
                            @click="redirect()"
                            >
                            {{ message.redirectLabel }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        components: {},
        props: {
            message: {
                required: true,
            },
            modal: {
                default: false
            },
        },
        data() {
            return {
                loading: true,
            };
        },
        methods: {
            close() {
                this.$emit('close');
            },
            redirect() {
                location.href = this.message.redirectTo;
            }

        },
        mounted() {
            setTimeout(() => this.loading = false, 50);
        },
    }
</script>
