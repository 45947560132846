<template>
    <div :id="`TextField_Holder-${uuid}`" class="field text" :class="{focused: focus || !!buffer}">
        <label class="left" :for="`TextField-${uuid}`">{{ properties.label }} <span v-if="properties.required">&nbsp;*</span></label>
        <div class="middleColumn">
            <input
                type="text"
                :name="`TextField-${uuid}`"
                class="text"
                :id="`TextField-${uuid}`"
                required="required"
                aria-required="true"
                v-model="buffer"
                @focus="focus=true"
                @blur="blur()"
            >
            <span v-if="(showErrorMessage && !valid )|| (!pristine && !valid)" class="message" :class="{bad: !valid}">{{ errorMeassge }}</span>
        </div>
    </div>
</template>

<script>
    import uuid from 'uuid4';

    function validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    export default {
        props: {
            properties: {
                required: true
            },
            value: {
                required: true
            },
        },
        data() {
            return {
                buffer: this.value,
                valid: false,
                uuid: uuid(),
                focus: false,
                pristine: true,
                errors: [],
                showErrorMessage: false,
                showErrorMessageTimeout: null,
            };
        },
        watch: {
            value() {
                this.buffer = this.value;
            },
            buffer() {
                this.validate();
                this.$emit('input', this.buffer);
            },
        },
        computed: {
            errorMeassge() {
                return this.errors.join(', ')
            }
        },
        methods: {
            blur() {
                this.pristine = false;
                this.focus = false;
            },
            patternValidation() {
                switch (this.properties.type) {
                    case('EmailField'):
                        if (this.buffer && !validateEmail(this.buffer)) {
                            this.errors.push('Invalid email address');
                        }
                        break;
                }
            },
            validate() {
                this.errors = [];
                this.patternValidation();

                if (!!this.properties.required && !this.buffer) {
                    this.errors.push('Field is required');
                }

                this.valid = this.errors.length === 0;
                this.$emit('validated');
            },
            showErrors() {
                clearTimeout(this.showErrorMessageTimeout);
                this.showErrorMessage = true;
            },
            closeErrors() {
                this.showErrorMessageTimeout = setTimeout(() => this.showErrorMessage = false, 1000);
            },
            getData() {
                return this.buffer;
            }
        },
        mounted() {
            this.validate();
        },
    };
</script>