<template>
    <div class="complaint-app-container">
        <complaint-process></complaint-process>
    </div>
</template>

<script>
    import ComplaintProcess from "./components/ComplaintProcess.vue";

    export default {
        name: "complaint-app",
        components: {
            ComplaintProcess
        },
    };
</script>

<style lang="scss">
    // @import "../../base_app/src/scss/abstracts/variables/colours.scss";
    // @import "../../base_app/src/scss/components/buttons.scss";
    // @import "../../base_app/src/scss/complaints-app/complaints-forms";
    // @import "../../base_app/src/scss/complaints-app/complaints-modals";
</style>
