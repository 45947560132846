<template>
    <button v-if="show" @click="toggle()" :class="[ isOn ? 'active' : 'not-active']">
        <slot></slot>
    </button>
</template>


<script>
    export default {
        props : {
            on: {
                type: Boolean,
                default: false,
            },
            show: {
                default: false,
            }
        },
        data() {
            return {
                isOn : this.on,
            }
        },
        methods: {
            toggle() {
                this.isOn = !this.isOn;
                this.$emit('click', this.isOn);
            },
            turnOff() {
                this.isOn = false;
            }
        },
    }
</script>