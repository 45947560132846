<template>
    <div :id="`CheckboxSetField_Holder-${uuid}`" class="field checkboxset">
        <!-- <label class="left" :for="`CheckboxSetField-${uuid}`">{{ properties.label }} <span v-if="properties.required">*</span></label>-->
        <editable-literal-field v-if="properties.label" :value="properties.label" :required="properties.required"/>

        <span v-if="showErrorMessage && !valid" class="message" :class="{bad: !valid}">Field is required</span>


        <div class="middleColumn typography">
            <ul :id="`CheckboxSet-${uuid}`" role="listbox">
                <li v-for="(option, i) in properties.options" :key="i" :class="(i + 1) % 2 ? 'odd' : 'even'">
                    <div class="checkbox-wrapper">

                        <input
                            :id="`Checkbox-${i}-${uuid}`"
                            class="checkbox"
                            :class="(i + 1) % 2 ? 'odd' : 'even'"
                            type="checkbox"
                            :name="`Option-${uuid}`"
                            v-model="option.checked"
                            @change="checked(option, i)"
                        >
                        <label :for="`Checkbox-${i}-${uuid}`">{{ option.label }}</label>
                    </div>
                    <!-- Tooltip -->
                    <toggle-button :show="!!option.tooltip" @click="toggleTooltip($event, i)" class="tooltip-toggle" ref="toggleTooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" width="24px">
                            <use xlink:href="/_resources/themes/base_dist/images/icons.svg#faq-icon"></use>
                        </svg>
                    </toggle-button>
                    <checkbox-field-tool-tip :text="option.tooltip" ref="tooltip"/>

                    <div class="more-details-wrapper" v-if="Array.isArray(option.moreDetails) && visibleMoreDetails && visibleMoreDetails[i]">
                        <div v-for="(field, i) in option.moreDetails" :key="`${i}`">
                            <text-area-input v-if="field.type==='TextareaField'" v-model="field.value" :properties="field" @validated="validate()" :no-margin="true"/>
                            <checkbox-sub-group-input v-if="field.type==='CheckboxGroup'" :properties="field" :ref="`checkboxSubGroup-${i}`"/>
                        </div>
                    </div>
                    <div class="more-details-wrapper" v-else-if="!Array.isArray(option.moreDetails) && option.moreDetails.type==='TextareaField' && visibleMoreDetails && visibleMoreDetails[i]">
                        <text-area-input v-model="option.moreDetails.value" :properties="option.moreDetails" @validated="validate()" :no-margin="true"/>
                    </div>
                    <div class="more-details-wrapper" v-else-if="!Array.isArray(option.moreDetails) && option.moreDetails.type==='CheckboxGroup' && visibleMoreDetails && visibleMoreDetails[i]">
                        <checkbox-sub-group-input :properties="option.moreDetails" :ref="`checkboxSubGroup-${i}`"/>
                    </div>
                </li>
            </ul>

        </div>
    </div>
</template>

<script>
    import uuid from 'uuid4';

    import CheckboxSubGroupInput from './CheckBoxSubGroupInput.vue';
    import EditableLiteralField from './EditableLiteralField.vue';
    import CheckboxFieldToolTip from './CheckboxFieldToolTip.vue';
    import TextAreaInput from './TextAreaInput.vue';
    import ToggleButton from './ToggleButton.vue';

    export default {
        components: {
            CheckboxSubGroupInput,
            CheckboxFieldToolTip,
            EditableLiteralField,
            TextAreaInput,
            ToggleButton
        },
        props: {
            properties: {
                required: true
            },
        },
        data() {
            return {
                valid: false,
                uuid: uuid(),
                showErrorMessage: false,
                showErrorMessageTimeout: null,
                visibleMoreDetails: null,
            };
        },
        methods: {
            checked() {
                this.validate();
                this.$emit('input');
                this.determineVisibleMoreDetails();
            },
            determineVisibleMoreDetails() {
                this.visibleMoreDetails = this.properties.options.map(opt => opt.checked);
            },
            validate() {
                if (this.properties.required) {
                    const checkedItems = this.properties.options.filter(opt => opt.checked);

                    this.valid = (checkedItems.length > 0) && checkedItems.every((option, i) => {
                        if (option.checked && Array.isArray(option.moreDetails)) {
                            return option.moreDetails.every(field => {
                                if (field.required && field.type==='TextareaField') {
                                    return field.value;
                                }
                                else if (field.required && field.type==='CheckboxGroup') {
                                    return this.$refs[`checkboxSubGroup-${i}`][0].valid;
                                }
                                return true;
                            });
                        }
                        else if (option.checked && option.moreDetails.required ) {
                            if (option.moreDetails.type==='TextareaField') {
                                return option.moreDetails.value;
                            }
                            if (option.moreDetails.type==='CheckboxGroup') {
                                return this.$refs[`checkboxSubGroup-${i}`][0].valid;
                            }
                        }
                        return true;
                    });
                } else {
                    this.valid = true;
                }

                this.$emit('validated');
            },
            toggleTooltip(isOn, index) {
                this.$refs.tooltip.forEach(tooltip => tooltip.close());
                this.$refs.toggleTooltip.forEach((toggleTooltip, i) => (index !== i) ? toggleTooltip.turnOff(): false);
                this.$refs.tooltip[index].show = isOn;
            },

            showErrors() {
                clearTimeout(this.showErrorMessageTimeout);
                this.showErrorMessage = true;
            },
            closeErrors() {
                // this.showErrorMessageTimeout = setTimeout(() => this.showErrorMessage = false, 1000);
            },
            getData() {
                const data = {}
                this.properties.options.forEach((option, i) => {
                    data[option.fieldname] = option.checked ? 1 : 0;
                    if (Array.isArray(option.moreDetails)) {
                        option.moreDetails.forEach(field => {
                            if (field.type==='TextareaField') {
                                data[field.fieldname] = field.value || '' ;
                            }
                            else if (field.type==='CheckboxGroup') {
                                if (this.$refs[`checkboxSubGroup-${i}`] !== undefined) {
                                    data[field.fieldname] = option.checked ? this.$refs[`checkboxSubGroup-${i}`][0].getData() : [];
                                }
                            }
                        });
                    }
                    else if (option.moreDetails) {
                        if (option.moreDetails.type==='TextareaField') {
                            data[option.moreDetails.fieldname] = option.moreDetails.value || '' ;
                        }
                        else if (option.moreDetails.type==='CheckboxGroup') {
                            if (this.$refs[`checkboxSubGroup-${i}`] !== undefined) {
                                data[option.moreDetails.fieldname] = option.checked ? this.$refs[`checkboxSubGroup-${i}`][0].getData() : [];
                            }
                        }
                    }
                });
                // eslint-disable-next-line no-console
                //console.log(data)
                return data;
            }
        },
        mounted() {
            // eslint-disable-next-line no-console
            //console.log(this.properties);
            this.determineVisibleMoreDetails();
            this.validate();
        },
    };
</script>
