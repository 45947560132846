<template>
    <div :id="`FormStep-${stepIndex}`" class="form-step-container" :class="{active: open}" :data-completed="`${completed}`">
        <div v-if="open" class="top-strip"></div>

        <div class="form-step-wrapper">

            <h2 @click="back()">{{ step.title }}</h2>

            <div :hidden="!open" class="complaint-form-styles">

                <div v-for="(field, i) in step.fields" :key="i">

                    <editable-literal-field 
                        v-if="field.type==='LiteralField'" 
                        :value="field.description" 
                        :required="field.required"
                    />

                    <text-input 
                        v-if="field.type==='TextField' || field.type==='EmailField'" 
                        v-model="field.value" 
                        :properties="field" 
                        ref="fieldInput" 
                        @validated="validate()"
                    />

                    <text-area-input 
                        v-if="field.type==='TextareaField'" 
                        v-model="field.value" 
                        :properties="field" 
                        ref="fieldInput" 
                        @validated="validate()"
                    />

                    <radio-button-group-input 
                        v-if="field.type==='RadioField'" 
                        v-model="field.value" 
                        :properties="field" 
                        ref="fieldInput" 
                        @validated="validate()"
                    />

                    <check-box-group-input 
                        v-if="field.type==='CheckboxGroup'" 
                        :properties="field" 
                        ref="fieldInput" 
                        @validated="validate()"
                    />

                    <dropdown-input 
                        v-if="field.type==='Dropdown' || field.type==='BroadcasterField'" 
                        v-model="field.value" 
                        :properties="field" 
                        ref="fieldInput" 
                        @validated="validate()"
                    />

                    <date-picker-input 
                        v-if="field.type==='DateField'" 
                        v-model="field.value" 
                        :properties="field" 
                        :disableFuture="true" 
                        ref="fieldInput" 
                        @validated="validate()"
                    />

                    <time-picker-input 
                        v-if="field.type==='TimeField'" 
                        v-model="field.value" 
                        :properties="field" 
                        :disableFuture="true" 
                        ref="fieldInput" 
                        @validated="validate()"
                    />

                    <file-picker-input 
                        v-if="field.type==='FileField'" 
                        :properties="field" 
                        ref="fieldInput" 
                        @validated="validate()"
                    />

                    <vue-recaptcha 
                        v-if="field.recaptchaSiteKey" 
                        class="recaptcha-wrapper" 
                        :sitekey="field.recaptchaSiteKey" 
                        @verify="onVerifyRecaptcha" 
                        @expired="onCaptchaExpired" 
                        ref="recaptcha"
                    />

                    <button v-if="field.type==='button'"
                        class="btn btn-hero" :class="{'can-click': valid && !submitting}"
                        @click="buttonAction(field.action)"
                        @mouseover="showErrorMessages()"
                        @mouseout="hideErrorMessages()"
                        >
                        {{ field.label }}
                    </button>

                </div>
            </div>
        </div>
    </div>
</template>


<script>

    import TextInput from '../form_inputs/TextInput.vue';
    import TextAreaInput from '../form_inputs/TextAreaInput.vue';
    import EditableLiteralField from '../form_inputs/EditableLiteralField.vue';
    import RadioButtonGroupInput from '../form_inputs/RadioButtonGroupInput.vue';
    import CheckBoxGroupInput from '../form_inputs/CheckBoxGroupInput.vue';
    import DropdownInput from '../form_inputs/DropdownInput.vue';
    import DatePickerInput from '../form_inputs/DatePickerInput.vue';
    import FilePickerInput from '../form_inputs/FilePickerInput.vue';
    import VueRecaptcha from 'vue-recaptcha';
    import TimePickerInput from '../form_inputs/TimePickerInput.vue';

    export default {
        components: {
            TextInput,
            TextAreaInput,
            EditableLiteralField,
            RadioButtonGroupInput,
            CheckBoxGroupInput,
            DropdownInput,
            DatePickerInput,
            FilePickerInput,
            VueRecaptcha,
            TimePickerInput,
        },
        props: {
            step: {
                required: true,
            },
            stepIndex: {
                type: Number,
                required: true,
            },
            open: {
                type: Boolean,
                default: false,
            },
            completed: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                valid: false,
                validRecaptcha: false,
                validRecaptchaKey: '',
                locationHRef: location.href.split("#")[0],
                submitting: false,
            }
        },
        mounted() {
        },
        methods: {
            buttonAction(action) {
                if (this.valid) {
                    this.$emit(action);
                    this.submitting = (action === 'submit');
                }
            },
            back() {
                this.$emit('back');
            },
            validate() {
                const validForm = this.$refs.fieldInput.every(input => input.valid);
                this.valid = this.$refs.recaptcha ? this.validRecaptcha && validForm : validForm;
                this.$emit('validated');
            },
            showErrorMessages() {
                this.$refs.fieldInput.forEach(input => input.showErrors());
            },
            hideErrorMessages() {

                this.$refs.fieldInput.forEach(input => {
                    input.closeErrors()
                });
            },
            onVerifyRecaptcha(response) {
                this.validRecaptcha = true;
                this.validRecaptchaKey = response;
                this.validate();
            },
            onCaptchaExpired: function () {
                this.$refs.recaptcha.reset();
                this.validRecaptcha = false;
                this.validRecaptchaKey = '';
            },
            resetSubmitting() {
                this.submitting = false;
            },
            getData() {
                const stepData = {};
                if(this.$refs.recaptcha) {
                    stepData.RecaptchaKey = this.validRecaptchaKey;
                }
                this.$refs.fieldInput.forEach(input =>  {
                    const data = input.getData();
                    if (data && typeof data === 'object' && !(data instanceof File) && !(data instanceof Date)) {
                        Object.keys(data).forEach(key => {
                            stepData[key] = data[key];
                        })
                    } else {
                        stepData[input.properties.fieldname] = data;
                    }
                });
                return stepData;
            },
        },
    }
</script>
