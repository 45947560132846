<template>
    <fieldset :id="`OptionsetField_Holder-${uuid}`" class="field optionset">
        <!-- <label class="left" :for="`OptionsetField-${uuid}`">{{ properties.label }} <span v-if="properties.required">*</span></label> -->

        <legend class="left">{{ properties.label }}</legend>
        <div class="middleColumn typography">
            <ul class="optionset" :id="`OptionsSet-${uuid}`" role="listbox">
                <li v-for="(option, i) in properties.options" :key="i">
                    <div class="radio-wrapper">
                        <input
                            :id="`Option-${i}-${uuid}`"
                            class="radio"
                            :class="(i + 1) % 2 ? 'odd' : 'even'"
                            type="radio"
                            :name="`Option-${uuid}`"
                            :value="option.value"
                            v-model="buffer"
                            >
                        <label :for="`Option-${i}-${uuid}`">{{ option.label }}</label>
                    </div>
                </li>
            </ul>
            <span v-if="showErrorMessage && !valid" class="message" :class="{bad: !valid}">Field is required</span>
        </div>
    </fieldset>
</template>

<script>
    import uuid from 'uuid4';

    export default {
        props: {
            properties: {
                required: true
            },
            value: {
                required: true
            },
        },
        data() {
            return {
                buffer: this.value,
                valid: false,
                uuid: uuid(),
                showErrorMessage: false,
                showErrorMessageTimeout: null,
            };
        },
        watch: {
            value() {
                this.buffer = this.value;
            },
            buffer() {
                this.validate();
                this.$emit('input', this.buffer);
            },
        },
        methods: {
            validate() {
                if (this.properties.required) {
                    this.valid = !!this.buffer;
                } else {
                    this.valid = true;
                }
                this.$emit('validated');
            },
            showErrors() {
                clearTimeout(this.showErrorMessageTimeout);
                this.showErrorMessage = true;
            },
            closeErrors() {
                this.showErrorMessageTimeout = setTimeout(() => this.showErrorMessage = false, 1000);
            },
            getData() {
                return this.buffer;
            }
        },
        mounted() {
            this.validate();
        },
    };
</script>