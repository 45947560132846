<template>
    <div>
        <h4>{{ properties.label }}</h4>
        <ul :id="`CheckboxSubSet-${uuid}`" role="listbox">
            <li v-for="(option, i) in properties.options" :key="`${i}`" :class="(i + 1) % 2 ? 'odd' : 'even'">
                <div class="checkbox-wrapper">
                    <input
                        :id="`Checkbox-${i}-${uuid}`"
                        class="checkbox"
                        :class="(i + 1) % 2 ? 'odd' : 'even'"
                        type="checkbox"
                        :name="`Option-${uuid}`"
                        v-model="option.checked"
                        @change="checked(option, i)"
                    >
                    <label :for="`Checkbox-${i}-${uuid}`">{{ option.label }}</label>
                </div>
            </li>
        </ul>
    </div>
</template>


<script>
    import uuid from 'uuid4';

    export default {
        props: {
            properties: {
                default: () => [],
            }
        },
        data() {
            return {
                valid: false,
                uuid: uuid(),
            };
        },
        methods: {
            checked() {
                this.validate();
                this.$emit('input');
            },
            validate() {
                if (this.properties.required) {
                    this.valid = this.properties.options.some(opt => opt.checked);
                } else {
                    this.valid = true;
                }
            },
            getData() {
                return this.properties.options.filter(opt => opt.checked).map(opt => opt.value);
            }
        },
        mounted() {
            this.validate();
        },
    }
</script>
