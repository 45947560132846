<template>
    <div class="form-stepper-container">
        <div v-if="submissionResponse" v-html="submissionResponse"></div>
        <div v-else>
            <bsa-form-step
                v-for="(step, i) in form.steps"
                :key="i"
                :step="dataService.formSteps[step]"
                :stepIndex ="i + 1"
                :open="i===currentStep"
                :completed="i<currentStep ? true: false"
                @next="next()"
                @back="back()"
                @submit="submit()"
                @validated="setConfirmBeforeLeavingThePage()"
                ref="step"
            />
        </div>
    </div>
</template>


<script>

    import FormStep from './FormStep.vue';
    import { dataService } from '../../services/DataService.js';

    export default {
        components: {
            'bsa-form-step': FormStep,
        },
        props: {
            form: {
                required: true,
            }
        },
        data() {
            return {
                dataSent: false,
                dataService,
                currentStep: 0,
                valid: false,
                submissionResponse: null,
                firstValidation: true,
            }
        },
        methods: {
            validateForm() {
                return this.$refs.step.every(step => step.valid);
            },
            back() {
                if (this.currentStep > 0) {
                    this.currentStep--;
                    this.scrollTo('.form-step-container.active');
                }
            },
            next() {
                if (this.currentStep < (this.form.steps.length - 1)) {
                    this.currentStep++;
                    this.scrollTo('.form-step-container.active');
                    localStorage.complaintStep = dataService.formSteps[this.form.steps[this.currentStep]].title;
                }
            },
            getSubmitData() {
                const submitData = {}

                this.$refs.step.forEach(step => {
                    const stepData = step.getData();
                    Object.keys(stepData).forEach(key => {
                        submitData[key] = stepData[key];
                    });
                })
                submitData['action_process'] = 'Submit';
                submitData['SecurityID'] = this.form.SecurityID;

                return submitData;
            },
            setConfirmBeforeLeavingThePage() {
                if (this.firstValidation) {
                    this.firstValidation = false;
                } else {
                    window.onbeforeunload = () => '';
                }
            },
            submit() {
                if (this.validateForm()) {
                    if(this.dataSent == false) {
                        //immediately stop, but allow again, just in case ...
                        this.dataSent = true;
                        window.setTimeout(
                            function(){
                                this.dataSent = false;
                            },
                            5000
                        );
                        this.dataService.post(this.getSubmitData(), this.form.postURL)
                            .then(res => {
                                this.submissionResponse = res.data;
                                window.onbeforeunload = null;
                                //we expect a small message not html with body tag.
                                if(res.data.indexOf('<body')!== -1) {
                                    alert('Sorry, we could not process your submission, please review your answers and re-submit.');
                                    window.location = window.location + '?novue=1'
                                } else {
                                    this.$emit('success');
                                    this.scrollTo('.complaint-app-container');
                                }
                            })
                            .catch(err => {
                                // We havent defined and error handling protocol for these cases.
                                // Simply printing them on the console for now
                                const N = this.$refs.step.length;
                                this.$refs.step[N - 1].resetSubmitting();
                                this.dataSent = false;
                                alert('Sorry, there was an error during the form data submission. Please contact us directly at info@bsa.govt.nz.');
                                window.console.error(err);
                            });
                    }
                }
            },
            scrollTo(selector, options){
                if(typeof options === 'undefined') {
                    options = {};
                }
                if(typeof options.timeBeforeStart === 'undefined') {
                    options.timeBeforeStart = 200;
                }
                if(typeof options.animationSpeed === 'undefined') {
                    options.animationSpeed = 300;
                }
                const mergedOptions =
                {
                    ...{
                        easing: 'ease-out',
                        offset: -60,
                        force: true,
                        cancelable: false,
                        x: false,
                        y: true

                    },
                    ...options
                }

                const VueScrollTo = require('vue-scrollto');
                window.setTimeout(
                    function() {
                        VueScrollTo.scrollTo(selector, options.animationSpeed, mergedOptions);
                    },
                    options.timeBeforeStart
                );

            }
        },
    }
</script>
