<template>
    <div 
        :id="`TextField_Holder-${uuid}`" 
        class="datepicker-container field text" 
        :class="{focused: focus || !!buffer}"
    >
        
        <label 
            class="left" 
            :for="`TextField-${uuid}`">{{ properties.label }} 
            
            <span v-if="properties.required">*</span>

        </label>
        
        <div class="middleColumn">

            <vue-timepicker
                :name="`TextField-${uuid}`"
                :id="`TextField-${uuid}`"
                @open="opened()"
                @close="closed()"
                @change="changed($event)"
                close-on-complete=true
                lazy=true
                placeholder=" "
                format="hh:mm A"
            >
            </vue-timepicker>

            <span 
                v-if="(showErrorMessage && !valid ) || (!pristine && !valid)" 
                class="message" 
                :class="{bad: !valid}">
                    {{ errorMessage }}
            </span>

        </div>

    </div>
</template>

<script>
    import uuid from 'uuid4'
    import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

    export default {
        components: {
            VueTimepicker
        },
        props: {
            properties: {
                required: true
            },
            value: {
                required: true
            },
            disableFuture: {
                default: false,
            }
        },
        data() {
            return {
                buffer: null,
                valid: false,
                uuid: uuid(),
                focus: false,
                pristine: true,
                errorMessage: '',
                showErrorMessage: false,
                showErrorMessageTimeout: null,
            };
        },
        methods: {
            changed(event) {
                this.buffer = event.displayTime
                this.validate();
                this.$emit('input', this.changeTimeFormatTo24Hours(event.displayTime));
            },

            validate() {
                const hourFormat = /^(0[1-9]|1[0-2]):/;
                const minuteFormat = /:[0-5][0-9] /;
                const meridiemFormat = /(AM|PM)$/;
                if (this.properties.required) {
                    if (!this.buffer) {
                        this.valid = false;
                        this.errorMessage = 'Field is required';
                    } else if (!hourFormat.test(this.buffer)) {
                        this.valid = false;
                        this.errorMessage = 'Please enter a valid hour (01-12)';
                    } else if (!minuteFormat.test(this.buffer)) {
                        this.valid = false;
                        this.errorMessage = 'Please enter valid minutes (00-59)';
                    } else if (!meridiemFormat.test(this.buffer)) {
                        this.valid = false;
                        this.errorMessage = 'Please specify AM or PM';
                    } else {
                        this.valid = true;
                        this.errorMessage = '';
                    }
                } else {
                    this.valid = !this.buffer || (hourFormat.test(this.buffer) && minuteFormat.test(this.buffer) && meridiemFormat.test(this.buffer));
                    this.errorMessage = '';
                }

                this.$emit('validated');
            },

            changeTimeFormatTo24Hours(time) {
                const match = time.match(/(\d+):(\d+)\s+(\w+)/);
                if (match) {
                    const [hour, minute, meridiem] = match.slice(1);
                    let hour24 = parseInt(hour, 10);
                    
                    if (meridiem === 'PM' && hour24 !== 12) {
                        hour24 += 12;
                    } else if (meridiem === 'AM' && hour24 === 12) {
                        hour24 = 0;
                    }
                    
                    return `${hour24.toString().padStart(2, '0')}:${minute}`;
                }

                return time;
            },

            opened() {
                this.focus = true;
            },

            closed() {
                this.pristine = false;
                if (!this.buffer) {
                    this.focus = false;
                }
            },

            showErrors() {
                clearTimeout(this.showErrorMessageTimeout);
                this.showErrorMessage = true;
            },

            closeErrors() {
                this.showErrorMessageTimeout = setTimeout(
                    () => this.showErrorMessage = false, 1000
                );
            },

            getData() {
                return this.buffer ? this.buffer : '';
            }
        },
        mounted() {
            this.validate();
        },
    };
</script>