import { BasicConnection } from './bsa_connection';


class DataService extends BasicConnection {
    constructor() {
        super();
    }

    /**
     * Endpoint is getjsonflat in JSONProvider.php
     */
    getData() {
        let url = 'complaintformjson/getjsonflat/';
        const settings = window.complaintAppSettings;
        if (settings && settings.complaintAppJSONURL) {
            url = settings.complaintAppJSONURL;
        }
        return this.get(url);
    }

    setData(data) {
        this.questionnaire = data.questionnaire;
        this.formSteps = data.form_steps || data.formSteps;
        this.forms = data.forms;
        this.messages = data.messages;
    }

}

// export new DataService();
export const dataService = new DataService();
