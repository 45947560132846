export const fileTypes = {
    doc: '.doc,application/msword',
    docx: '.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    jpeg: '.jpeg, image/jpeg',
    jpg: '.jpg, image/jpg',
    pdf: '.pdf, application/pdf',
    png: '.png, image/png',
    txt: '.txt'
};

// https://w3c.github.io/html/sec-forms.html#element-attrdef-input-accept

// .ace,application/x-ace-compressed
// .arc,application/x-freearc
// .arj
// .asf,video/x-ms-asf
// .au,audio/basic
// .avi,video/x-msvideo
// .bmp,image/bmp
// .bz2,application/x-bzip2
// .cab,application/vnd.ms-cab-compressed
// .cda
// .csv,text/plain
// .dmg,application/x-apple-diskimage
// .doc,application/msword
// .docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document
// .dotx,application/vnd.openxmlformats-officedocument.wordprocessingml.template
// .flv,video/x-flv
// .gif,image/gif
// .gpx,application/gpx+xml
// .gz,application/gzip
// .hqx,application/mac-binhex40
// .ico,image/x-icon
// .jpg
// .kml,application/vnd.google-earth.kml+xml
// .m4a
// .m4v,video/x-m4v
// .mid,audio/midi
// .midi
// .mkv,video/x-matroska
// .mov,video/quicktime
// .mp3,audio/mpeg
// .mp4,video/mp4
// .mpa
// .mpeg,video/mpeg
// .mpg
// .ogg,audio/ogg
// .ogv,video/ogg
// .pages
// .pcx,image/x-pcx
// .pdf,application/pdf
// .pps,application/vnd.ms-powerpoint
// .ppt
// .pptx,application/vnd.openxmlformats-officedocument.presentationml.presentation
// .potx,application/vnd.openxmlformats-officedocument.presentationml.template
// .ra,audio/x-pn-realaudio
// .ram
// .rm,application/vnd.rn-realmedia
// .rtf,application/rtf
// .sit,application/x-stuffit
// .sitx,application/x-stuffitx
// .tar,application/x-tar
// .tgz
// .tif,image/tiff
// .tiff
// .txt
// .wav,audio/x-wav
// .webm,video/webm
// .wma,audio/x-ms-wma
// .wmv,video/x-ms-wmv
// .xls,application/vnd.ms-excel
// .xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
// .xltx,application/vnd.openxmlformats-officedocument.spreadsheetml.template
// .zip,application/zip
// .zipx
// .graphql
