<template>
    <div class="checkbox-tooltip-container">
        <div v-if="show" class="more-info" v-html="text">
        </div>
    </div>
</template>


<script>
    export default {
        props: {
            text: {
                type: String,
            }
        },
        data() {
            return {
                show: false,
            }
        },
        methods: {
            open() {
                this.show = true;
            },
            close() {
                this.show = false;
            },
            toggle() {
                this.show = !this.show;
            }
        },
    }
</script>


